import { useState, useEffect } from 'react';

const useScript = (src) => {
    const [status, setStatus] = useState(src ? 'loading' : 'idle');

    useEffect(() => {
        if (!src) {
            setStatus('idle');
            return;
        }

        let script = document.querySelector(`script[src="${src}"]`);

        if (!script) {
            script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.onload = () => setStatus('ready');
            script.onerror = () => setStatus('error');
            document.head.appendChild(script);
        } else if (script.dataset.status === 'ready') {
            setStatus('ready');
        }

        return () => {
            if (script) {
                script.dataset.status = 'idle';
            }
        };
    }, [src]);

    return status;
};

export default useScript;
